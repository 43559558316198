.custom-checkbox label {
  position: relative;
  cursor: pointer;
  &.checked {
    &:before {
      background-color: $petroleum;
      border-color: $petroleum;
      color: #fff;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E");
      transform: translateY(-50%) scale(1);
      opacity: 1;
      top: 0.85rem;
    }
  }
}

.checkbox {
  &__item {
    .custom-control-label {
      position: static;
      display: flex;

      > span {
        margin-left: 30px;
      }

      &:after {
        left: auto;
        top: auto;
      }

      &.checked {
        &:after {
          top: 13px;
        }
      }

      &:before {
        left: auto;
        top: 3px;
      }
    }

    &--right {
      .custom-control-label {
        &:before,
        &:after {
          left: auto;
          right: 16px;
          top: 13px;
        }

        &.checked {
          &:after {
            top: 23px;
          }
        }
      }
    }

    /*.custom-control-input {
      position: static;
    }*/

    &--plain {
      background-color: $white;
      display: flex;
      border-radius: $border-radius-lg;
      margin-bottom: 12px;

      svg {
        margin-right: 16px;
      }

      .custom-checkbox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          width: 100%;
          font-size: $font-size-md;
          font-weight: 600;
          cursor: pointer;
          padding: 16px 24px;

          &:before,
          &:after {
            content: none;
          }
        }
      }

      &.checkbox__item--checked {
        background-color: $primary;

        label {
          color: $white;
        }
      }
    }

    &--multi-button {
      border-radius: 200px;

      label {
        background-color: $white;
        padding: 8px 12px;
        border-radius: 200px;

        &:before,
        &:after {
          content: none;
        }
      }

      &.checkbox__item {
        span {
          margin: 0;
        }
        &--checked {
          label {
            background-color: $secondary;
            color: $white;
          }
        }
      }
    }
  }
}
